<template>
    <v-container fluid class="mx-auto">
        <v-row class="mx-auto mt-5">
            <v-col cols="12" lg="3" md="3">
                <v-dialog width="500" v-model="dialogUpdate">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="d-flex justify-center" v-bind="attrs" v-on="on" block rounded large
                            :disabled="!available" @click="updateInfo">Modify User</v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="d-flex justify-center">
                            <p>Modify User</p>
                        </v-card-title>
                        <v-card-text class="mt-5">
                            <v-form>
                                <v-text-field v-model="updatedUser.name" disabled rounded dense solo label="Nom">
                                </v-text-field>
                                <v-text-field v-model="updatedUser.password" rounded dense solo label="New Password">
                                </v-text-field>
                                <v-select v-model="updatedUser.role" clearable dense rounded solo label="Roles"
                                    :items="comboRoles"></v-select>
                                <v-select v-model="updatedUser.status" clearable dense rounded solo label="Status"
                                    :items="userStatus"></v-select>
                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn text color="success" @click="saveUpdate" :loading="loadingUpdateUser">Save</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="dialogUpdate = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
            <v-col offset-lg="6" offset-md="6" cols="12" lg="3" md="3">
                <v-dialog width="500" v-model="dialogNew">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn class="d-flex justify-center" v-bind="attrs" v-on="on" block rounded large>Add New User
                        </v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="d-flex justify-center">
                            <p>Add New User</p>
                        </v-card-title>
                        <v-card-text class="mt-5">
                            <v-form>
                                <v-text-field v-model="newUserInfo.name" rounded dense solo label="Nom"></v-text-field>
                                <v-text-field v-model="newUserInfo.password" rounded dense solo label="Password">
                                </v-text-field>
                                <v-select v-model="newUserInfo.role" clearable dense rounded solo label="Roles"
                                    :items="comboRoles"></v-select>
                                <v-select v-model="newUserInfo.status" clearable dense rounded solo label="Status"
                                    :items="userStatus"></v-select>
                            </v-form>
                        </v-card-text>
                        <v-divider></v-divider>
                        <v-card-actions class="d-flex justify-center">
                            <v-btn text color="success" @click="createUser" :loading="loadingNewUser">Save</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="dialogNew = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <hr />
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center">
                <span class="text-body-1 text-md-h4 text-lg-h4">FreshPay Congo's Users Lists</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-data-table v-model="selectecUser" item-key="name" show-select :single-select="true"
                        :headers="headers" :items="users"></v-data-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-divider></v-divider>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" class="col-md-3">
                <v-btn block rounded large>Delete Role</v-btn>
            </v-col>
            <v-col cols="12" class="col-md-3 offset-md-6">
                <v-dialog v-model="dialogNewRole" width="500">
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn block rounded large v-bind="attrs" v-on="on">Add Role</v-btn>
                    </template>
                    <v-card>
                        <v-card-title class="text-h5 grey lighten-2 d-flex justify-center">
                            Create New Role
                        </v-card-title>
                        <v-card-text class="mt-10">
                            <v-text-field rounded dense solo label="role name" v-model="newRole.name">
                            </v-text-field>
                            <v-text-field type="number" min="0" max="5" rounded dense solo label="role level"
                                v-model="newRole.level"></v-text-field>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn text color="success" @click="createRole" :loading="loadingNewRole">create</v-btn>
                            <v-spacer></v-spacer>
                            <v-btn text color="red" @click="dialogNewRole = false">Cancel</v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
        <v-row>
            <v-col class="d-flex justify-center">
                <span class="text-body-1 text-md-h4 text-lg-h4">FreshPay Congo's Available Role</span>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card rounded="xl">
                    <v-data-table :headers="roleHeaders" :items="roles" show-select :single-select="true">
                    </v-data-table>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from "vuex";

import services from "../services/services";

export default {
    data: () => ({
        dialogNewRole: false,
        loadingNewUser: false,
        loadingNewRole: false,
        loadingUpdateUser: false,
        newRole: {
            "name": "",
            "level": -10
        },
        newUserInfo: {
            role: "",
            status: "",
            name: "",
            password: "",
            createdby: ""
        },
        updatedUser: {
            role: "",
            status: "",
            name: "",
            password: "",
            createdby: ""
        },
        userStatus: ["ACTIVE", "DISABLED", "SUSPENDED"],
        roles: [],
        comboRoles: [],
        users: [],
        selectecUser: [],
        dialogUpdate: false,
        dialogNew: false,
        headers: [
            { text: "Nom", value: "name", align: "center" },
            { text: "Roles", value: "role", align: "center" },
            { text: "Date de Création", value: "created_at", align: "center" },
            { text: "Dernière mise à jour", value: "updated_at", align: "center" },
            { text: "Status", value: "status", align: "center" },
            { text: "Creér par", value: "created_by", align: "center" },
        ],
        roleHeaders: [
            { text: "Nom", value: "nom", align: "center" },
            { text: "CREATED AT", value: "created_at", align: "center" },
            { text: "LEVEL", value: "level", align: "center" },

        ]
    }),
    methods: {
        async createRole() {
            this.loadingNewRole = true
            await services.create_role(this.newRole);
            this.loadingNewRole = false
        },
        async saveUpdate() {
            this.loadingUpdateUser = true
            console.log(this.updatedUser)
            let response = await services.update_user(this.updatedUser)
            console.log(response);
            this.loadingUpdateUser = false
        },
        async createUser() {
            this.loadingNewUser = true
            this.newUserInfo.createdby = this.userlogged.name
            let response = await services.create_user(this.newUserInfo);
            console.log(response);
            this.loadingNewUser = false
        },
        updateInfo() {
            const user = this.selectecUser[0]
            this.updatedUser.role = user.role
            this.updatedUser.status = user.status
            this.updatedUser.name = user.name
            this.updatedUser.createdby = user.created_by
        }
    },
    computed: {
        ...mapState(['userlogged']),
        available() {
            return this.selectecUser.length == 1 ? true : false
        }
    },
    async mounted() {
        const tempRoles = await services.get_roles();
        this.roles = tempRoles;
        this.comboRoles = tempRoles.map(el => el.nom);
        this.users = await services.get_users();


    }
} 
</script>


<style scoped>
</style>