<template>
    <div>
        <v-container fluid>
            <v-row class="mx-auto">
                <v-col class="d-flex justify-center">
                    <p class="text-h4">
                        Pendings Payouts
                    </p>
                </v-col>
            </v-row>
            <v-row class="mx-auto">
                <v-divider></v-divider>
            </v-row>
            <v-row class="mx-auto mt-5 d-flex justify-end">
                <v-col cols="12" class="col-md-4">
                    <v-menu ref="menuStart" v-model="menuStart" :return-value.sync="dates" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y min-width="auto">
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="dates" solo clearable rounded label="Choisir une intervalle"
                                prepend-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                        <v-date-picker range v-model="dates">
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menuStart = false">
                                Cancel
                            </v-btn>
                            <v-btn text color="primary" @click="$refs.menuStart.save(dates)">
                                OK
                            </v-btn>
                        </v-date-picker>
                    </v-menu>
                </v-col>
                <v-col cols="12" class="col-md-4">
                    <v-btn large rounded block @click="getPayouts" :loading="loading">
                        GET PAYOUTS
                    </v-btn>
                </v-col>
            </v-row>
            <v-row class="mx-auto">
                <v-col>
                    <v-card rounded="xl">
                        <v-card-text>
                            <v-data-table :headers="headers" :loading="loadingTable" :items="items">
                                <template v-slot:item.validate="{ item }">
                                    <v-btn rounded text color="success" :disabled="disabledSwitch"
                                        @click="sendToSwitch(item)">
                                        Send to Switch
                                    </v-btn>
                                </template>
                            </v-data-table>
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
            <v-row>
                <v-col>
                    <v-snackbar top v-model="snackbar">
                        {{ text }}

                        <template v-slot:action="{ attrs }">
                            <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                                Close
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-col>
            </v-row>
        </v-container>
    </div>
</template>


<script>
import services from "../services/services"

export default {
    data: () => ({
        dates: [],
        menuStart: false,
        loading: false,
        disabledSwitch: false,
        text: "",
        snackbar: false,
        loadingTable: false,
        headers: [
            { text: "Merchant Code", align: "center", value: "merchant_code" },
            { text: "Merchant Reference", align: "center", value: "merchant_reference" },
            { text: "Operator", align: "center", value: "operator" },
            { text: "Amount", align: "center", value: "amount" },
            { text: "Currency", align: "center", value: "currency" },
            { text: "Customer Number", align: "center", value: "customer_details" },
            { text: "PayDRC Reference", align: "center", value: "paydrc_reference" },
            { text: "Status", align: "center", value: "status" },
            { text: "Action", align: "center", value: "action" },
            { text: "Created At", align: "center", value: "created_at" },
            { text: "Updated At", align: "center", value: "updated_at" },
            { text: "Actions", align: "center", value: "validate" },
        ],
        items: []
    }),
    methods: {
        async getPayouts() {
            this.loadingTable = true
            this.loading = true
            if(this.dates.length === 1){
                this.dates.push(this.dates[0])
            }
            const payouts = await services.get_payouts(this.dates)
            this.items = payouts
            this.loading = false
            this.loadingTable = false
        },
        async sendToSwitch(item) {
            this.disabledSwitch = true
            const redo = await services.replay_payouts(item)
            this.disabledSwitch = false

            if (redo === true) {
                this.text = "Transaction send to switch successfully"
                this.snackbar = true
            }
            else {
                this.text = "Failed to send transaction to switch"
                this.snackbar = true
            }

            await this.getPayouts()
        }
    }

}
</script>